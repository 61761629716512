var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view" }, [
    _c(
      "header",
      { staticClass: "header header__gradient header__gradient--fromBottom" },
      [
        _c("div", { staticClass: "container container--sm" }, [
          _c("h1", { staticClass: "h1 h1--lg" }, [
            _vm._v(_vm._s(_vm.instrument ? _vm.instrument.name : "Loading...")),
          ]),
        ]),
      ]
    ),
    _c("section", { staticClass: "section" }, [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("BeatTable", {
            attrs: {
              parentState: _vm.ParentStateType.Instrument,
              beatPlaylist: _vm.beats,
            },
            on: {
              fetchMoreData: function ($event) {
                return _vm.updateBeatsTable($event, _vm.beats.length)
              },
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }