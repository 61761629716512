











import Vue from 'vue'
import { Prop, Component, Watch, Mixins } from 'vue-property-decorator'
// components
import BeatTable from '@/components/BeatTable.vue'

// api
import { fetchBeats, BeatFilterType } from '~/api/beats'
import { InfiniteLoadingSetState } from '~/mixins/infinite-loading'
import { fetchSingleInstrument } from '@/api/instrument'
import { ParentStateType } from '~/const/parent-state-type'

@Component({ components: { BeatTable } })
export default class InstrumentPage extends Mixins(InfiniteLoadingSetState) {
    @Prop({ type: String }) slug!: string

    ParentStateType = ParentStateType

    instrument: IInstrument = null
    beats: IBeat[] = []

    get backgroundImageLink() {
        return this.instrument ? `url(${this.instrument.image_background_url})` : 'url()'
    }

    async fetchSingleInstrument() {
        this.instrument = await fetchSingleInstrument(this.slug)
    }

    async updateBeatsTable($state: any, offset: number) {
        // call the store to update the beats
        const beats = await fetchBeats({ filterType: BeatFilterType.MainInstrument, slug: this.slug, offset })
        this.beats.length > 0 ? this.beats.push(...beats) : (this.beats = beats)
        // set loading state for vue-infinite-loading
        this.setLoadingStateAndUpdatePlayerQueue($state, beats)
    }

    fetchEverything() {
        this.fetchSingleInstrument()
        this.updateBeatsTable(null, 0)
    }

    @Watch('slug')
    onSlugChange(newValue: string) {
        // reset beats list
        this.beats = []
        this.fetchEverything()
    }

    mounted() {
        this.fetchEverything()
    }
}
